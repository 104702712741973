$main_font: 'Signika Negative';
//$main_font: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';

// Colors
$color_primary: #dc4605;
$color_secondary: #006987;
$color_alert: #f44336;

$background_light: #eee;
$background_dark: #222;

$status_anwesend: #6aaf4d;
$status_pause: #f39c12;
$status_unterwegs: #3e9ddd;
$status_homeoffice: #000;
$status_abwesend: grey;
$status_urlaub: #6aaf4d;
$status_elternzeit: #87ceeb;
$status_krank: #fab1a0;

// Images
$url_aachen: url('../../images/aachen.png');
$url_berlin: url('../../images/berlin.png');
$url_offsite: url('../../images/offsite.png');
$url_logo: url('../../images/e-dynamics_Logo_RGB.svg');

// Sizes
$profile_size_small: 90px;
$profile_size: 95px;
$profile_size_big: 105px;

// Shadows
//$shadow_main: -4px 4px 15px 0 #aaa;
$shadow_main: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
$shadow_edged: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

// Borders
$border_light: #eee;
$dialog_radius: 4px;
