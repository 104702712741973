// Date Widget
.date-widget {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
  color: $color_primary;
  border-bottom: 1px solid $color_primary;

  h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  p {
    margin: 0;
    font-size: 0.9em;
  }
}

// Own Profile
.ownProfile {
  position: relative;

  .name {
    position: absolute;
    left: 0;
    right: 0;
    top: 3%;
    margin: auto;
    width: fit-content;
    padding: 5px 10px;
    display: none;
  }

  .location {
    position: absolute;
    left: 0;
    right: 0;
    top: 70%;
    bottom: 0;
    margin: auto;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 7%;
    z-index: 1;
  }
}

// Settings & Logout
.logout-btn,
.settings-btn {
  position: absolute;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaa;
  right: 15px;
  bottom: 10px;

  &:hover {
    color: $color_secondary;
    border-color: $color_secondary;
  }
}

.logout-btn {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
}

// e-dynamics Logo
.logo-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .logo {
    height: 85px;
    width: 170px;
    background-image: $url_logo;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media screen and (min-width: 1500px) {
    .logo {
      height: 90px;
      width: 185px;
    }
  }
  @media screen and (min-width: 2000px) {
    .logo {
      height: 100px;
      width: 200px;
    }
  }
}
