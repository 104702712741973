.circular-menu {
  width: 220px;
  height: 220px;
  margin: 0 auto;
  position: relative;
}

.circle {
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid;
  width: 220px;
  height: 220px;
  opacity: 0;

  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);

  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.open.circle {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/*.menuOverlay a {
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  text-align: center;
}*/

.circle a:hover {
  color: #eef;
}

.menu-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-decoration: none;
  text-align: center;
  color: #444;
  border-radius: 50%;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  padding: 10px;
  background: #dde;
}

.menu-button:hover {
  background-color: #eef;
}
