// TAB MENU
.MuiTab-root {
  font-size: 1rem !important;
  text-transform: none !important;
}

.tabMenu-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  width: calc(100% - 0.8rem);
}

.tabMenu {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 62px;
  width: 600px;
  margin: auto;
  box-shadow: $shadow_main !important;
}

@media screen and (max-width: 1000px) {
  .tabMenu {
    position: fixed;
  }
}

// TAB PANEL
.tabPanel {
  height: 83vh;
  max-height: 600px;
  padding: 30px 60px;
}

.settings-wrapper {
  position: relative;
  height: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: $dialog_radius;
}

// BOTTOM NAV
.bottomNav {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: $dialog_radius;
  border-bottom-right-radius: $dialog_radius;
}

// SETTINGS FORM
.settings-form {
  position: relative;
  padding: 30px 40px;
  height: calc(100% - 56px);

  &.config {
    padding: 20px;
  }

  .form-row-Wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 40px;

    &.nameInputs,
    &.second {
      align-items: stretch;
      height: 70px;
      margin-bottom: 20px;

      p {
        font-size: 0.65rem;
      }
    }

    // for image button
    .MuiButton-root {
      text-transform: none !important;
    }
  }
}

// items in settings form
.form-row-Wrapper > div {
  width: 100px;

  &.birthday {
    max-width: 140px;
  }
  &.phoneNumber {
    width: 200px;
  }
  &.add-image,
  &.add-description {
    width: fit-content;
  }
  &.password-input {
    width: 200px;
  }
}

// SELECTION DIALOG
.selection-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: wrap;
  padding: 15px;
  overflow: auto;
  width: 100%;
  max-height: calc(100% - 56px);

  .selection-item {
    height: 35px;
    font-size: 0.8rem;
    margin-bottom: 15px;
    text-transform: none;
  }
}

// STATUS
.status-input {
  position: absolute;
  width: 150px;
  left: 0;
  right: 0;
  top: 100px;
  margin: auto;
}

.status-hint {
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  margin: auto;
  width: 380px;
  height: fit-content;
  padding: 0px 15px;
  font-size: 0.825rem;
  background-color: rgba(255, 152, 0, 0.1);
  color: #444;
  border-radius: $dialog_radius;

  &.warning {
    background-color: rgba(255, 152, 0, 0.1);
    border: 1px solid #ff9800;
  }
  &.success {
    background-color: rgba(76, 175, 80, 0.1);
    border: 1px solid rgb(76, 175, 80);
  }
}

// DELETE ITEM (Profile, Status)
.delete-item {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  margin: auto;

  // Profile
  &.profile-item {
    width: 100px;
    height: 100px;

    .profile {
      opacity: 1;
      height: 100%;
      box-shadow: none;
      border-color: #bbb !important;
      cursor: auto;
      .statusBadge {
        display: none;
      }
      .profile-image {
        opacity: 1;
        filter: none;
      }
    }

    .profile-name {
      text-align: center;
    }
  }

  // Status
  &.status-item {
    padding: 10px;
    width: fit-content;
    min-width: 90px;
    height: 90px;
    border: 1px solid;
    box-shadow: $shadow_main;

    .icon-wrapper {
      height: 50%;
      position: relative;
      i {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .status-name {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        text-align: center;
      }
    }
  }
}

// SUBMIT BUTTON
.submitSettings {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding-bottom: inherit;

  &.config {
    bottom: 20px;
  }
}

// CONFIG, DESCRIPTION AREA
.config-area,
.description-area {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: $dialog_radius;

  textarea {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    padding: 10px;
    border-radius: $dialog_radius;
  }
}
.config-area {
  height: 100%;
}
.description-area {
  height: 85%;
}

// BACK BUTTON, CLOSE BUTTON
.back-button,
.close-button {
  position: absolute;
  color: #bbb;

  &:hover {
    color: $color_secondary;
    cursor: pointer;
  }
}

.back-button {
  position: absolute;
  left: 40px;
  width: 30px;
  bottom: 34px;
}

.close-button {
  right: 5px;
  top: 5px;
}

// IMAGE MENU, DESCRIPTION MENU
.image-menu,
.description-menu {
  position: relative;
  padding: 30px 40px;
  width: 100%;
  height: calc(100% - 56px);
}

.image-menu > input {
  font-size: 0.9rem;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.image-editor-wrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25px;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    margin-top: 5px;
    width: 200px;
  }
}

// PASSWORD FORM
.password-form {
  width: 100%;
  height: 150px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
