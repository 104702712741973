// Status Menu
.status-menu {
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;

  &.big {
    width: calc(100% - 0.8rem);
    .menuOverlay {
      width: 308px;
      height: 308px;
      bottom: 20%;
    }

    a {
      transform: scale(1.4);

      &:hover {
        transform: scale(1.5);
      }
    }

    .profile {
      &.menu-shown {
        width: 119px !important;
        height: 119px !important;
        bottom: 20%;
      }
    }
  }
}
.menu-area {
  .status-menu {
    z-index: 1;
    height: 100%;
  }
}

// Menu Overlay
.menuOverlay {
  background-color: #eee;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 10%;
  right: 0;
  margin: auto;
  width: 220px;
  height: 220px;
  //box-shadow: $shadow_edged;
  box-shadow: $shadow_main;
  transition: all 0.5s ease;

  a {
    opacity: 0.4;
    position: absolute;
    margin-left: -20px;
    margin-top: -17px;
    width: 35px;
    height: 35px;
    border: 2px solid;
    border-radius: 50%;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }

  span {
    font-size: 1.3rem;
  }

  .selected {
    opacity: 1;
    transform: scale(1.1);
    background-color: #fff;
    border: 2px solid;
    box-shadow: $shadow_main;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.profile {
  &.withMenu {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 10%;
    margin: auto;
    width: $profile_size;
    height: $profile_size;
    display: block;
    box-shadow: none;
    &.menu-shown {
      width: 85px !important;
      height: 85px !important;
      z-index: 1;
    }
  }
}

@media screen and (min-width: 1500px) and (min-height: 900px) {
  .profile {
    &.withMenu {
      width: $profile_size;
      height: $profile_size;
    }
  }
}
@media screen and (min-width: 2500px) {
  .ownProfile {
    .profile {
      width: $profile_size_big;
      height: $profile_size_big;
    }
  }
}
