// Location Image
.location-image {
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
  border-bottom: 1px solid $border_light;

  &.aachen {
    background-image: $url_aachen;
  }
  &.berlin {
    background-image: $url_berlin;
    height: 45px;
  }
  &.offsite {
    background-image: $url_offsite;
  }
}

// Profile Section
.profile-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, $profile_size_small);
  grid-gap: 25px;
  grid-auto-rows: $profile_size_small;
  justify-content: space-between;
  margin-bottom: 2em;
}

// normal tablets
@media screen and (max-width: 1400px) and (max-height: 900px) {
  .profile-section {
    grid-template-columns: repeat(auto-fill, 85px);
    grid-gap: 20px;
    grid-auto-rows: 85px;
    margin-bottom: 0.5em;
  }
  .location-image {
    margin-bottom: 0.5em;
  }
}

// small tablets
@media screen and (max-width: 1100px) and (max-height: 800px) {
  .profile-section {
    grid-template-columns: repeat(auto-fill, 80px);
    grid-gap: 15px;
    grid-auto-rows: 80px;
  }
}

@media screen and (min-width: 1500px) and (min-height: 900px) {
  .profile-section {
    grid-gap: 27px;
    grid-template-columns: repeat(auto-fill, $profile_size);
    grid-auto-rows: $profile_size;
  }
}
@media screen and (min-width: 2500px) {
  .profile-section {
    grid-template-columns: repeat(auto-fill, $profile_size_big);
    grid-auto-rows: $profile_size_big;
  }
}
// styling for last profile section
.location-wrapper:last-child {
  .profile-section {
    margin-bottom: 0;
  }
}

// Profile
.profile {
  border: 2px solid;
  border-radius: 50%;
  position: relative;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
  }
  &.available {
    opacity: 1;
    box-shadow: $shadow_main;
  }

  .profile-image {
    height: 100%;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-color: #aaa;
    background-blend-mode: screen;
    filter: grayscale(1);

    &.available {
      filter: none;
      background-blend-mode: unset;
    }
  }
}

// styling that should not apply to profile with status menu
.profile-section {
  .profile:hover {
    transform: scale(1.1);
  }
}

// Status Badge
.statusBadge {
  position: absolute;
  right: -3%;
  top: -3%;
  background-color: #fff;
  border: 1px solid;
  border-radius: 50%;
  padding: 0.1rem;
  width: 1.8rem;
  height: 1.8rem;
  text-align: center;
  line-height: 2.2;

  span {
    font-size: 1.3rem;
  }
}

// PROFILE INFORMATION

.profile-info-wrapper {
  position: relative;
  height: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  padding: 30px 50px;
  border-radius: $dialog_radius;
}

.information-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // profile
  .profile-item {
    width: 120px;
    height: 120px;

    .profile {
      opacity: 1;
      height: 100%;
      box-shadow: none;
      border-color: #bbb !important;
      cursor: auto;
      .statusBadge {
        display: none;
      }
      .profile-image {
        opacity: 1;
        filter: none;
      }
    }
  }

  .key-infos-wrapper {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.key-infos-wrapper > div {
  width: fit-content;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;

  p {
    margin-left: 20px;
  }

  .phone-number {
    color: green;
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }

  .birthday {
    margin-bottom: 7px;
  }
}

.description-wrapper {
  width: 100%;
  height: 300px;
  //border: 1px solid $color_primary;
  margin-top: 35px;
  border-radius: $dialog_radius;
  box-shadow: $shadow_edged;

  .description-headline {
    width: 100%;
    height: 35px;
    background-color: $color_primary;
    color: #fff;
    border-top: 1px solid $color_primary;
    border-top-right-radius: $dialog_radius;
    border-top-left-radius: $dialog_radius;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description-content {
    width: 100%;
    height: calc(100% - 35px);
    padding: 20px;

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      resize: none;
      border-radius: $dialog_radius;
      background-color: #fcfcfc;
    }
  }
}

$anime-time: 8s;

$box-size: 200px;
$clip-distance: 0.05;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;

$main-color: #69ca62;

$codepen-logo-path: url('//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png');

%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.profile.animated {
  border: none;
  &::before,
  &::after {
    @extend %full-fill;
    content: '';
    z-index: -1;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width;
    animation: clipMe $anime-time linear infinite;
    border-radius: 50%;
  }

  .statusBadge {
    display: none;
  }

  &::before {
    animation-delay: $anime-time * -0.5;
  }
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, $clip-size, $path-width, 0px);
  }
  25% {
    clip: rect(0px, $path-width, $clip-size, 0px);
  }
  50% {
    clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px);
  }
  75% {
    clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width);
  }
}
