// Login Screen
.loginScreen {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  padding-top: 10vh;
  background-color: $background_light;
}

// Login Form Container
.loginContainer {
  width: 30%;
  min-width: 300px;
  max-width: 500px;
  height: 80%;
  min-height: 400px;
  max-height: 500px;
  padding: 2% 3%;
  box-shadow: $shadow_main;
  border-radius: $dialog_radius;
  background-color: #fff;

  h1 {
    font-size: 1.8rem;
    color: $color_primary;
  }
}

// Login Form
.loginForm {
  margin-top: 15%;

  .loginItem.password {
    margin-top: 5%;
  }
}

// Login Button
.loginButton {
  width: 100%;
  margin-top: 15% !important;
  padding: 4% !important;

  &:hover {
    background-color: $color_secondary;
  }
}

// Login Error - Alert
.login-alert {
  display: 'block';
  margin-top: '2rem';
  margin-bottom: 0;
  color: $color_alert;
}
