// Main Screen
main.content {
  height: 100vh;
  padding: 0.8rem;
  padding-bottom: 0;
  display: grid;
  //grid-template-columns: 4fr 1fr;

  // change styling for devices
  &.device {
    grid-auto-rows: min-content auto;
    //grid-template-columns: none;

    .profile-area {
      padding: 0 1em 0 1em;
    }
  }
}

// handle sidebar and calendar for smaller devices
@media screen and (max-width: 1000px) {
  .sidebar-trigger {
    height: 90px;
    width: 20px;
    position: absolute;
    top: calc(50% - 45px);
    left: -20px;
    background-color: #eee;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #ddd;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
    flex-flow: column;

    &:hover {
      cursor: pointer;
    }
  }

  main.content:not(.device) {
    //grid-template-columns: none;

    .menu-area {
      //margin-bottom: 0;
      top: 0.8rem;
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 3;
      transition: all 0.6s ease;

      &.hided {
        min-width: 0;
        width: 0;
        padding: 0;
      }
    }

    .profile-area {
      // make right padding smaller bc no sidebar
      padding: 0 1em 0 1em;
      //transition: all 0.8s ease;
    }

    .status-menu.big {
      position: fixed;
    }

    // small tablet or large smartphone
    @media screen and (max-width: 500px) {
      .menu-area {
        min-width: 300px;
      }
    }

    // small smartphone
    @media screen and (max-width: 320px), (max-height: 500px) {
      .calendar-wrapper {
        display: none;
      }
      .menu-area {
        grid-template-rows: 50px 50vh auto;
        min-width: 270px;
        height: auto;
      }
    }
  }
}

// Profile Area
.profile-area {
  position: relative;
  padding: 0 3em 0 1em;
  transition: all 0.8s ease;
}

@media screen and (min-width: 1001px) {
  main.content:not(.device) {
    .profile-area {
      // calculate full width minus padding of main content minus menu area size + margin
      width: calc(100vw - 1.6rem - 400px);
    }
  }
}

@media screen and (min-width: 1500px) and (min-height: 900px) {
  .profile-area {
    margin: 0 120px 0 120px;
  }
  main.content:not(.device) {
    .profile-area {
      // calculate full width minus padding of main content minus menu area size + margin
      width: calc(100vw - 1.6rem - 640px);
    }
  }
}
@media screen and (min-width: 1800px) {
  .profile-area {
    margin: 0 180px 0 180px;
  }
  main.content:not(.device) {
    .profile-area {
      // calculate full width minus padding of main content minus menu area size + margin
      width: calc(100vw - 1.6rem - 760px);
    }
  }
}
@media screen and (min-width: 2000px) {
  .profile-area {
    margin: 0 300px 0 300px;
  }
  main.content:not(.device) {
    .profile-area {
      // calculate full width minus padding of main content minus menu area size + margin
      width: calc(100vw - 1.6rem - 1000px);
    }
  }
}

// Menu Area
.menu-area {
  position: fixed;
  display: grid;
  right: 0.8rem;
  grid-template-rows: 50px 25vh 50vh auto;
  text-align: center;
  background-color: #fbfbfb;
  padding: 0 1rem;
  box-shadow: $shadow_edged;
  border-radius: $dialog_radius;
  min-width: 400px;
  bottom: 0.8rem;
  height: calc(100vh - 1.6rem);

  &.hideable {
    width: 320px;
    top: 0.8;
    right: 0;
    z-index: 100;
    position: absolute;

    &.hidden {
      opacity: 0;
    }
  }

  &.device {
    display: flex;
    position: relative;
    right: 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    min-width: 0;
    height: auto;

    .settings-date-wrapper {
      display: flex;
      width: 250px;
      justify-content: space-between;

      .settings-btn {
        position: static;
        align-items: flex-end;
        padding: 0;
        z-index: 1;
      }
      .date-widget {
        border: none;

        @media screen and (max-width: 450px) {
          .dateString {
            display: none;
          }
          .settings-date-wrapper {
            width: 50%;
          }
        }

        h1 {
          font-size: 1.4rem;
        }
      }
    }

    .logo {
      height: 70px;
    }
  }
}

@media screen and (min-height: 870px) {
  .menu-area {
    grid-template-rows: 50px 25vh 55vh auto;
  }
}
.menu-area::before {
  background: url(../../images/e-dynamics_Raster_RGB_schwarz.png);
  content: '';
  background-size: contain;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.25;
}

.menu-area.device::before {
  display: none;
}

// Loading Animation
.loading-animation {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-snackbar {
  [dir='rtl'] & {
    display: contents !important;
  }
}

#refresh-hint {
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  margin: auto;
  width: 500px;
  border: 1px solid;
  border-radius: 4px;
  z-index: 3;
  background-color: #333;
  color: #fff;
  font-size: 18px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  text-align: center;

  #refreshButton-wrapper {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    #refreshButton {
      font-size: 70px;

      &:hover {
        cursor: pointer;
        color: #aaa;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #refresh-hint {
    width: 300px;
    font-size: 16px;
  }
}
