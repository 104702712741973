.calendar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#cal-widget {
  position: relative;
  background: #f9f9f9;
  height: 90%;
  width: 100%;
  padding: 1em;
  max-width: 400px;
  box-shadow: $shadow_edged;
  border-radius: $dialog_radius;
  overflow-y: auto;

  h4 {
    margin-bottom: 3px;
    text-align: start;
    margin-top: 0;
  }

  .loading-animation {
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}

#cal-widget-wrapper {
  height: 100%;
  width: 100%;
}

#cal-widget-future {
  padding-bottom: 15px;
}
.cal-widget-item:last-child {
  margin-bottom: 10px;
}

.cal-widget-item {
  /*background: linear-gradient(60deg, rgba(149, 165, 166, 1) 0%, rgba(255, 255, 255, 1.0) 20%);*/
  position: relative;
  box-sizing: border-box;
  height: 45px;
  border-bottom: 1px solid #7f8c8d;
  opacity: 1;
  transition: all 0.7s ease-out;
}
/*.cal-widget-item-icon:before {
  background: rgba(149, 165, 166, 0.4);
  color: rgba(52, 73, 94, 1);
  content: 'event';
  border: 2px solid rgba(52, 73, 94, 1);
  border-radius: 100px;
  padding: 5px;
}*/

#cal-widget .cal-widget-item:last-child {
  border: none !important;
}

/*.eventcat-urlaub .cal-widget-item-icon:before {
  background: rgba(39, 174, 96, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'beach_access';
}

.eventcat-geburtstag .cal-widget-item-icon:before {
  background: rgba(231, 76, 60, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'cake';
}

.eventcat-feiertag .cal-widget-item-icon:before {
  background: rgba(241, 196, 15, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'star';
}

.eventcat-elternzeit .cal-widget-item-icon:before {
  background: rgba(155, 89, 182, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'child_friendly';
}

.eventcat-reise .cal-widget-item-icon:before {
  background: rgba(52, 152, 219, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'flight_takeoff';
}

.eventcat-homeoffice .cal-widget-item-icon:before {
  content: 'home';
}

.eventcat-kundenbesuch .cal-widget-item-icon:before {
  background: rgba(52, 152, 219, 0.5);
  color: rgba(52, 73, 94, 1);
  content: 'people_alt';
}

.eventcat-teilzeit .cal-widget-item-icon:before {
  content: 'golf_course';
}*/

.cal-widget-active .cal-widget-item:first-child,
.cal-widget-future .cal-widget-item:first-child {
  margin-top: 0px;
  height: 500px;
}

.cal-widget-item-icon,
.cal-widget-item-type,
.cal-widget-item-duration,
.cal-widget-item-user,
.cal-widget-item-location,
.cal-widget-item-daterange {
  position: absolute;
  font-size: 12px;
  color: #7f8c8d;
}

.cal-widget-item-icon {
  left: 5px;
  top: 5px;
  line-height: 45px;
  font-size: 22px;
  color: #000;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
}

.cal-widget-item-type {
  left: 50px;
  bottom: 5px;
  letter-spacing: 0.2px;
}

.cal-widget-item-duration {
  right: 5px;
  bottom: 5px;
}

.cal-widget-item-user {
  top: 5px;
  left: 50px;
  //line-height: 50px;
  font-size: 16px;
  color: #000;
}

.cal-widget-item-location {
  left: 50px;
  bottom: 5px;
}

.cal-widget-item-daterange {
  right: 5px;
  bottom: 5px;
}

#cal-widget-loadmore {
	cursor: pointer;
	color: #555;
}

#cal-widget-loadmore:hover span {
	color: $color_secondary;
}

#cal-widget-loadmore-icon {
	line-height: 50px;
	margin-right: 5px;
}

#cal-widget-loadmore-text {
	position: relative;
  top: -7px;
}
